<template>
  <div>
    <v-sheet class="elevation-1 pa-5" id="link-content">
      <h3>{{ $t('missions.types.section_title', {mission: 'Update'}) }}</h3>
      <v-container>

        <p>{{ $t('missions.types.no_content') }}</p>

      </v-container>
    </v-sheet>
  </div>
</template>

<script>

import clonedeep from "lodash.clonedeep";
import ImageBrowser from "@/components/images/ImageBrowser";
import {EventBus} from "@/event-bus";

export default {
  name: "Update",

  components: {ImageBrowser},

  props: {
    id: Number
  },

  data() {
    return {
      defaultContent: {
        content: '',
        url: '',
        background: {},
        image: {}
      },
      content: {}
    }
  },

  mounted() {
    if (!this.id) {
      this.content = clonedeep(this.defaultContent)
    } else {
      this.content = this.$store.getters['mission/getMissionContent']
    }
  },

  computed: {
    hasBg() {
      return this.content.background && this.content.background.ios
    },
    hasImg() {
      return this.content.image && this.content.image.ios
    },
  },

  methods: {
    openImageDialog(category, obj) {
      EventBus.$emit('open-image-dialog', category, obj)
    },
  },

  watch: {
    "content": {
      handler: function() {
        this.$emit('update:content', this.content)
      },
      deep: true
    }
  },

}
</script>

<style scoped>

</style>
<template>
  <v-row>
    <v-col>
      <v-alert :type="(isActive) ? 'success' : 'warning'">
        {{ (isActive) ? $t('missions.status.active') : $t('missions.status.inactive') }}
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>

export default {
name: "Status",

  props: {
    active: {
      type: Boolean
    }
  },

  computed: {
    isActive() {
      return this.active
    }
  }
}
</script>

<style scoped>

</style>
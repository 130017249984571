<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="700px"
        persistent
        fullscreen
        id="mission-dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            class="mb-2"
            v-bind="attrs"
            v-on="on"
            :disabled="isLoading"
        >
          {{ $t('commons.actions.new') }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="primary white--text text-uppercase">
          {{ formTitle }}
        </v-card-title>
        <v-divider></v-divider>
        <v-container>

<!--          <v-row v-if="!isEditing">-->
<!--            <v-col>-->
<!--              <v-alert type="info">-->
<!--                {{ $t('missions.create.form_dialog_notice') }}-->
<!--              </v-alert>-->
<!--            </v-col>-->
<!--          </v-row>-->

          <status :active="mission.active"></status>

          <v-form
              class="pb-16"
              v-if="showForm"
              ref="form"
              v-model="valid"
              lazy-validation
          >

            <v-row>
              <v-col
                  cols="12"
                  class="pt-0 pb-5"
              >
                <testing-switch
                    :label="$t('commons.fields.testing.label')"
                    :value.sync="mission.testing"
                    :message="$t('commons.fields.testing.help_text')"
                ></testing-switch>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row>
              <v-col
                  cols="12"
                  md="6"
                  class="pt-0 pb-5"
              >
                <v-switch
                    class="mt-1"
                    v-model="mission.never_expire"
                    :label="$t('missions.fields.never_expire.label')"
                    inset
                    color="success"
                    :messages="$t('missions.fields.never_expire.help_text')"
                >
                </v-switch>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="pt-2 pb-5"
              >
                <v-select
                  :items="[null,'birthday']"
                  v-model="mission.condition"
                  outlined
                  dense
                  label="condition"
                  :messages="'Condizioni per poter essere aggiunta alla lista delle missioni utente. (Es. missione birthday)'"
                ></v-select>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-divider></v-divider>

            <!-- GENERAL MISSION DATA -->
            <form-content-title :title="$t('missions.form-section.general')"/>
            <v-row class="mb-20">

              <v-col cols="8" class="border-right">

                <v-row>
                  <v-col cols="6">
                    <v-text-field
                        v-model="mission.description.title"
                        :rules="requiredRules"
                        :label="$t('missions.fields.description.title')"
                        required
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                        v-model="mission.slug"
                        :rules="requiredRules"
                        :label="$t('missions.fields.slug')"
                        required
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <app-version-select
                        :required-rules="requiredRules"
                        :value.sync="mission.min_app_version"
                        :label="$t('commons.fields.min_app_version')"
                    ></app-version-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="mission.description.sub_title"
                        :label="$t('missions.fields.description.sub_title')"
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                      cols="7"
                  >
                    <v-textarea
                        v-model="mission.description.text"
                        :label="$t('missions.fields.description.text')"
                        rows="3"
                        outlined
                        dense
                    ></v-textarea>
                  </v-col>
                  <v-col
                      cols="5"
                  >
                    <v-text-field
                        v-model="mission.description.text_action"
                        :label="$t('missions.fields.description.text_action')"
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                      cols="6"
                      md="3"
                  >
                    <mission-types
                        :type.sync="mission.type"
                        :required-rules="requiredRules"
                        :disabled="isEditing"
                        @change="changeTypeConfirm"
                    ></mission-types>
                  </v-col>

                  <v-col
                      cols="6"
                      md="3"
                  >
                    <countries
                        :country.sync="mission.country"
                        :required-rules="requiredRules"
                    ></countries>
                  </v-col>

                  <v-col
                      cols="6"
                      md="3"
                  >
                    <v-text-field
                        v-model="mission.position"
                        :rules="requiredRules"
                        :label="$t('missions.fields.position')"
                        required
                        type="number"
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>

                  <v-col
                      cols="6"
                      md="3"
                  >
                    <v-text-field
                        v-model="mission.points"
                        :rules="requiredRules"
                        :label="$t('missions.fields.points')"
                        required
                        type="number"
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>

                </v-row>

                <v-row>

                  <v-col
                  >
                    <v-switch
                        class="mt-1"
                        v-model="mission.active"
                        :label="$t('missions.fields.active.label')"
                        inset
                        color="success"
                        :messages="$t('missions.fields.active.help_text')"
                    >
                    </v-switch>


                  </v-col>

                  <v-col
                      cols="4"
                      md="3"
                  >
                    <datepicker
                        :date.sync="mission.start"
                        :required-rules="requiredRules"
                        :required="true"
                        :label="$t('missions.fields.start')"
                    ></datepicker>
                  </v-col>

                  <v-col
                      cols="4"
                      md="3"
                  >
                    <datepicker
                        :date.sync="mission.end"
                        :required-rules="requiredRules"
                        :required="true"
                        :label="$t('missions.fields.end')"
                    ></datepicker>
                  </v-col>

                  <v-col
                      cols="4"
                      md="3"
                  >
                    <v-text-field
                        v-model="mission.delay"
                        :rules="requiredRules"
                        :label="$t('missions.fields.delay')"
                        required
                        type="number"
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>

                </v-row>

                <v-row>
                  <v-col>
                    <mission-parent
                        :mission.sync="mission.parent_id"
                        :disabled="parentsDisabled"
                    ></mission-parent>
                  </v-col>
                </v-row>

              </v-col>
              <v-col cols="4">
                <v-row>

                  <v-col
                      cols="6"
                      class="text-center"
                  >
                    <image-upload id="mission_backgroun"
                                  :image.sync="mission.description.background"
                                  :required-rules="requiredRules"
                                  :required="true"
                                  btn-label="background"
                                  :label="$t('images.categories.background')"
                    ></image-upload>
                  </v-col>

                  <v-col
                      cols="6"
                      class="text-center"
                  >
                    <image-upload id="mission_image"
                                  :image.sync="mission.description.image"
                                  btn-label="image"
                                  :label="$t('images.categories.image')"
                    ></image-upload>
                  </v-col>

                </v-row>
              </v-col>
            </v-row>

            <!-- MISSION BUTTON DATA -->
            <form-content-title :title="$t('missions.form-section.button')"/>
            <v-row>
              <v-col cols="8" class="border-right">

                <v-text-field
                    v-model="mission.button.title"
                    :rules="requiredRules"
                    :label="$t('missions.fields.button.title')"
                    required
                    outlined
                    dense
                ></v-text-field>

                <v-text-field
                    v-model="mission.button.sub_title"
                    :rules="requiredRules"
                    :label="$t('missions.fields.button.sub_title')"
                    required
                    outlined
                    dense
                ></v-text-field>

              </v-col>
              <v-col cols="4">
                <image-upload id="mission_btn_image"
                              :image.sync="mission.button.image"
                              :required-rules="requiredRules"
                              :required="true"
                              btn-label="image"
                              max-width="250"
                              height="200"
                ></image-upload>
              </v-col>

            </v-row>

            <!-- OPEN AND CLOSE PAGE -->
            <form-content-title :title="$t('missions.form-section.open_and_close_pages')"/>
            <v-row>
              <v-col cols="8" class="border-right">
                <v-text-field
                    v-model="mission.description.page_close_title"
                    :label="$t('commons.fields.title')"
                    outlined
                    dense
                ></v-text-field>

                <v-text-field
                    v-model="mission.description.page_close_subtitle"
                    :label="$t('commons.fields.subtitle')"
                    outlined
                    dense
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-row>

                  <v-col
                      cols="6"
                      class="text-center"
                  >
                    <image-upload id="mission_page_close_bg"
                                  :image.sync="mission.description.page_close_bg"
                                  btn-label="background"
                                  :label="$t('images.categories.background')"
                    ></image-upload>
                  </v-col>

                  <v-col
                      cols="6"
                      class="text-center"
                  >
                    <image-upload id="mission_page_close_image"
                                  :image.sync="mission.description.page_close_image"
                                  btn-label="background"
                                  :label="$t('images.categories.image')"
                    ></image-upload>
                  </v-col>

                </v-row>
              </v-col>
            </v-row>


            <!-- componente dimanico per contenuto missione-->
            <components
                :is="mission.type"
                :id="mission.id"
                :content.sync="mission.content"
                :form="$refs.form"
            />

          </v-form>

          <v-divider></v-divider>

          <json-debug :objs="[{mission: mission}]"
                      add-class="mb-16"
          />

          <v-footer fixed color="primary">
            <v-row id="footer-dialog">
              <v-col>
                <dialog-buttons
                    :valid="valid"
                    :is-loading="isLoading"
                    @save="storeMission"
                    @close="close"
                ></dialog-buttons>
              </v-col>
            </v-row>
          </v-footer>

        </v-container>

        <overlay-loader
            :is-loading="isLoading"
            :absolute="true"
            :opacity="0.7"
        ></overlay-loader>

      </v-card>

    </v-dialog>

  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import moment from 'moment'
import clonedeep from 'lodash.clonedeep'
import Datepicker from "@/components/form/Datepicker";
import DialogButtons from "@/components/form/DialogButtons";
import MissionTypes from "@/components/form/MissionTypes";
import Countries from "@/components/form/Countries";
import MissionParent from "@/components/form/MissionParent";
import ImageUpload from "@/components/form/ImageUpload";
import * as components from '@/components/missions/_partials'
import FormContentTitle from "@/components/commons/FormContentTitle";
import Status from "@/components/missions/_partials/Status";
import AppVersionSelect from "@/components/form/AppVersionSelect";
import TestingSwitch from "@/components/form/TestingSwitch";
import {validationRules} from "@/mixins/validationRules";

import {EventBus} from "@/event-bus";

export default {
  name: "Form",

  mixins: [validationRules],

  components: {
    Datepicker,
    DialogButtons,
    MissionTypes,
    Countries,
    MissionParent,
    ImageUpload,
    FormContentTitle,
    Status,
    AppVersionSelect,
    TestingSwitch,
    ...components
  },

  data: () => ({
    isEditing: false,
    showForm: false,
    mission: {},
    alertText: '',
    alertDialog: false,
    oldType: '',
    missionBackupContent: {},
    defaultMission: {
      content: {},
      description: {
        title: '',
        sub_title: '',
        text: '',
        text_action: '',
        condition: '',
        image: {
          id: ''
        },
        background: {
          id: ''
        },
        page_close_title: '',
        page_close_subtitle: '',
        page_close_bg: {
          id: ''
        },
        page_close_image: {
          id: ''
        },
      },
      slug: '',
      type: '',
      country: 'it',
      position: '1',
      points: '',
      never_expire: 0,
      start: moment().toISOString().substr(0, 10),
      end: moment().add(1, 'year').toISOString().substr(0, 10),
      delay: '0',
      active: false,
      button: {
        title: '',
        sub_title: '',
        image: {
          id: ''
        },
      },
      min_app_version: '',
      testing: true,
    },
    dialog: false,
    sending: false,
    valid: false,
    types: [],
    parentsDisabled: true
  }),

  props: {
    messageSuccess: String,
    messageError: String
  },

  computed: {
    ...mapGetters({
      isLoading: 'mission/loadingState',
      missionParentCountry: 'mission/getParentMissionCountry',
      buttons: 'button/getButtons'
    }),
    formTitle() {
      return (this.isEditing === true) ? this.$t('missions.edit.title') : this.$t('missions.create.title')
    }
  },

  mounted() {
    let self = this

    this.mission = clonedeep(this.defaultMission)

    EventBus.$on('edit-mission', function (mission) {
      console.log('edit mission ' , mission)
      self.$store.dispatch('mission/setMission', mission.id)
          .then(() => {
            self.mission = self.$store.getters['mission/getMission']
            if (self.mission.country != '') {
              self.parentsDisabled = false
            }
          })
      self.isEditing = true
      self.dialog = true
    })

    this.showForm = true
  },

  methods: {

    changeTypeConfirm() {
      if (this.oldType != undefined) {
        this.alertText = 'Attenzione, tutte le modifiche ai contenuti della missione andranno persi'
        this.alertDialog = true
      }
    },

    changeType(proced) {
      this.manageTypeChange = true
      if (proced) {
        console.log('proced')
        // this.mission.content = {}
      } else {
        console.log('cancel')
        this.mission.type = this.oldType
        this.mission.content = this.missionBackupContent
      }
      this.alertDialog = false
      this.manageTypeChange = false
    },

    async storeMission() {
      const valid = await this.$refs.form.validate()

      if (valid) {
        if (!this.isEditing) {
          await this.$store.dispatch('mission/storeMission', this.mission)
              .then(resp => {
                console.log('then close dialog resp ', resp)
                this.close()
              })
              .catch(err => {
                console.log('create error err ', err)
              })
        } else {
          await this.$store.dispatch('mission/updateMission', this.mission)
              .then(resp => {
                console.log('then close dialog resp ', resp)
                this.close()
              })
              .catch(err => {
                console.log('create error err ', err)
              })
        }
      }
    },

    close() {
      this.dialog = false
      this.valid = true
      this.$refs.form.reset()
      this.$nextTick(() => {
        this.isEditing = false
        this.mission = clonedeep(this.defaultMission)
      })
    },
  },

  watch: {
    "mission.country": {
      handler: function (country, oldCountry) {
        if (country && country !== oldCountry && country !== this.missionParentCountry) {
          this.$store.dispatch('mission/setParentMissions', country)
          this.parentsDisabled = false
        }
      },
      deep: true
    },
    "mission.type": {
      handler: function (type, oldType) {
        this.oldType = oldType
      },
      deep: true
    }
  },
}
</script>

<style scoped>
.bordered {
  border: 1px solid #dddddd;
}

.mission-content {
  margin-top: 20px;
  /*background-color: #efefef;*/
  border: 1px solid #3B190C;
  border-radius: 5px;
}

.mission-content >>> .v-input__slot {
  /*background-color: white;*/
}

.mission-content >>> .v-image {
  /*background-color: white;*/
}

</style>

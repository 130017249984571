<template>
    <div>

        <template v-if="isLoading">
            <v-skeleton-loader
                class="mx-auto"
                max-width="90%"
                type="card"
            ></v-skeleton-loader>

            <v-skeleton-loader
                class="mx-auto mt-5"
                max-width="90%"
                type="card"
            ></v-skeleton-loader>
        </template>

        <template v-else>

        <v-sheet
            :color="`grey ${this.$vuetify.theme.isDark ? 'darken-2' : 'lighten-4'}`"
            class="px-3 pt-3 pb-3"
        >

                <!-- condo details card -->
                <v-card
                    class="mx-auto"
                    outlined
                >
                    mission details<br>
                    <components :is="$route.params.type" :id="$route.params.id"/>
                </v-card>
                <!-- end condo details card -->

        </v-sheet>

        </template>

    </div>
</template>

<script>

    import {mapGetters} from "vuex";
    import * as components from '@/components/missions/_partials'

    export default {
        name: "Show",

        data () {
            return {
            }
        },

        components: {
          ...components
        },

        mounted() {
            this.init()
        },

        methods: {

            init() {
                this.$store.dispatch('mission/setMission', this.$route.params.id)
            },
        },

        computed: {
            ...mapGetters({
                isLoading: 'mission/loadingState',
              mission: 'mission/getMission'
            }),
        }
    }
</script>

<style scoped>
</style>

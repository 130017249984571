<template>
  <v-sheet class="elevation-1 pa-5 mission-content"
           id="product-content"
  >
    <form-content-title :title="$t('missions.types.section_title', {mission: 'Link'})"/>

    <v-container>
      <v-row>
        <v-col id="link-content">

          <v-row>

            <v-col cols="4">
              <v-text-field
                  v-model="content.action"
                  :label="$t('missions.types.link.action')"
                  :rules="requiredRules"
                  outlined
                  dense
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field
                  v-model="content.text_action"
                  :label="$t('missions.types.link.text_action')"
                  :rules="requiredRules"
                  outlined
                  dense
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-select
                  v-model="content.type_action"
                  :label="$t('missions.types.link.type_action')"
                  :items="actionTypes"
                  :rules="requiredRules"
                  outlined
                  dense
              ></v-select>
            </v-col>
          </v-row>

          <v-row>

            <v-col cols="4">

              <image-upload v-if="content.background"
                            id="mission_link_background"
                            :image.sync="content.background"
                            :required-rules="requiredRules"
                            :required="true"
                            btn-label="background"
              ></image-upload>

            </v-col>

            <v-col cols="4">

              <image-upload v-if="content.image"
                            id="mission_link_image"
                            :image.sync="content.image"
                            :required-rules="requiredRules"
                            :required="true"
                            btn-label="image"
              ></image-upload>

            </v-col>

          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>

import clonedeep from "lodash.clonedeep";
import ImageUpload from "@/components/form/ImageUpload";
import FormContentTitle from "@/components/commons/FormContentTitle";
import {validationRules} from "@/mixins/validationRules";

export default {
  name: "Link",

  mixins: [validationRules],

  components: {ImageUpload, FormContentTitle},

  props: {
    id: Number,
    form: Object
  },

  data() {
    return {
      defaultContent: {
        text_action: '',
        type_action: '',
        background: {
          id: ''
        },
        image: {
          id: ''
        },
        action: ''
      },
      content: {},
      actionTypes: [
        '_blank',
        'app'
      ]
    }
  },

  mounted() {
    if (!this.id) {
      this.content = clonedeep(this.defaultContent)
    } else {
      this.content = this.$store.getters['mission/getMissionContent']
      // fix per import dump incompleti
      if (!this.content) this.content = clonedeep(this.defaultContent)
    }
    this.form.resetValidation()
  },

  watch: {
    "content": {
      handler: function () {
        this.$emit('update:content', this.content)
      },
      deep: true
    }
  },

}
</script>

<style scoped>

</style>
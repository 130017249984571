<template>

  <v-sheet class="elevation-1 pa-5 mission-content"
           id="product-content"
  >
    <form-content-title :title="$t('missions.types.section_title', {mission: 'Products'})"/>
    <v-container>

      <v-row>
        <v-col cols="8">
          <v-row>
            <v-col cols="6">
              <v-text-field
                  v-model="content.title"
                  :label="$t('products.fields.title')"
                  :rules="requiredRules"
                  outlined
                  dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="content.description"
                  :label="$t('missions.types.products.description')"
                  :rules="requiredRules"
                  outlined
                  dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <p>{{ $t('products.fields.products.label')}}</p>
              <v-row>
                <v-col
                    v-for="p in content.products"
                    :key="p.id"
                    class="d-flex child-flex"
                    cols="3"
                >
                  <v-card flat tile class="d-flex">
                    <v-img
                        :src="(p.image) ? p.image.url : '@/assets/no-image.png'"
                        class="border-all"
                        aspect-ratio="1"
                        max-width="200"
                    >

                      <v-tooltip bottom light>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              fab
                              x-small
                              class="float-right mr-1 mt-1"
                              color="#BCAAA4"
                              v-bind="attrs"
                              v-on="on"
                              @click="removeProduct(p.id)"
                          >
                            <v-icon>
                              {{ $t('icons.trash') }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <small>{{ $t('commons.actions.remove') }}</small>
                      </v-tooltip>

                    </v-img>

                  </v-card>
                </v-col>
              </v-row>
            </v-col>

            <product-browser v-if="content.products"
                             :products.sync="content.products"
            ></product-browser>

          </v-row>

        </v-col>

        <v-col
            cols="4"
            class="text-center"
        >
          <image-upload id="mission_background"
                        :image.sync="content.background"
                        :required-rules="requiredRules"
                        :required="true"
                        :label="$t('missions.types.products.background')"
                        btn-label="background"
          ></image-upload>
        </v-col>

      </v-row>

    </v-container>
  </v-sheet>

</template>

<script>

import clonedeep from "lodash.clonedeep";
import ProductBrowser from "@/components/products/ProductBrowser";
import ImageUpload from "@/components/form/ImageUpload";
import FormContentTitle from "@/components/commons/FormContentTitle";
import {validationRules} from "@/mixins/validationRules";

export default {
  name: "Product",
  components: {ProductBrowser, ImageUpload, FormContentTitle},
  mixins: [validationRules],

  props: {
    id: Number,
    form: Object
  },

  data() {
    return {
      defaultContent: {
        title: '',
        description: '',
        background: {
          id: ''
        },
        products: []
      },
      content: {},
    }
  },

  mounted() {
    if (!this.id) {
      this.content = clonedeep(this.defaultContent)
    } else {
      this.content = this.$store.getters['mission/getMissionContent']
      // fix per import dump incompleti
      if (!this.content) this.content = clonedeep(this.defaultContent)
    }
    this.form.resetValidation()
  },

  methods: {
    removeProduct(id) {
      let removeIndex = this.content.products.map(product => product.id).indexOf(id)
      ~removeIndex && this.content.products.splice(removeIndex, 1)
    },
  },

  watch: {
    "content": {
      handler: function () {
        this.$emit('update:content', this.content)
      },
      deep: true
    }
  },
}
</script>

<style scoped>

</style>
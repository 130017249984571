<template>
  <div>
    <v-sheet class="elevation-1 pa-5" id="choose-content">
      <form-content-title :title="$t('missions.types.section_title', {mission: 'Choose'})"/>

      <v-container>

        <v-row>

          <v-col cols="6">
            <v-text-field
                v-model="content.description"
                :label="$t('missions.types.choose.description')"
                :rules="requiredRules"
                outlined
                dense
            ></v-text-field>
          </v-col>

          <v-col
              cols="6"
              class="text-center"
          >
            <template v-if="hasBg">
              <p>Background</p>
              <v-img
                  :src="content.background.url"
                  class="pointer mb-5 pa-10 bordered"
                  contain
              />
            </template>
            <image-browser category="mission_choose_background"
                           img-obj="background"
                           :image.sync="content.background"
            ></image-browser>
          </v-col>

        </v-row>

      </v-container>
    </v-sheet>
  </div>
</template>

<script>

  import clonedeep from "lodash.clonedeep";
  import ImageBrowser from "@/components/images/ImageBrowser";
  import {validationRules} from "@/mixins/validationRules";
  import FormContentTitle from "@/components/commons/FormContentTitle";

  export default {
    name: "Choose",

    mixins: [validationRules],

    components: {ImageBrowser, FormContentTitle},

    props: {
      id: Number,
      form: Object
    },

    data() {
      return {
        defaultContent: {
          description: '',
          background: {},
        },
        content: {},
      }
    },

    mounted() {
      if (!this.id) {
        this.content = clonedeep(this.defaultContent)
      } else {
        this.content = this.$store.getters['mission/getMissionContent']
      }
      this.form.resetValidation()
    },

    computed: {
      hasBg() {
        return this.content.background && this.content.background.ios
      },
    },

    watch: {
      "content": {
        handler: function() {
          this.$emit('update:content', this.content)
        },
        deep: true
      }
    },
  }
</script>

<style scoped>

</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-divider',{staticClass:"my-3"}),_c('commons-filter',{attrs:{"loading":_vm.isLoading,"freeText":_vm.filters.name,"country":_vm.filters.country,"appVersion":_vm.filters.min_app_version,"testing":_vm.filters.testing,"free-search-filter":true,"country-filter":true,"app-version-filter":true,"testing-filter":true},on:{"update:freeText":function($event){return _vm.$set(_vm.filters, "name", $event)},"update:free-text":function($event){return _vm.$set(_vm.filters, "name", $event)},"update:country":function($event){return _vm.$set(_vm.filters, "country", $event)},"update:appVersion":function($event){return _vm.$set(_vm.filters, "min_app_version", $event)},"update:app-version":function($event){return _vm.$set(_vm.filters, "min_app_version", $event)},"update:testing":function($event){return _vm.$set(_vm.filters, "testing", $event)},"change":_vm.applyFilter}}),_c('v-data-table',{staticClass:"elevation-0 clickable",attrs:{"loading":_vm.isLoading,"loading-text":_vm.$t('commons.loading'),"headers":_vm.headers,"items":_vm.missions,"items-per-page":_vm.pagination.per_page,"hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.description.title",fn:function(ref){
var item = ref.item;
return [_c('b',{class:{'blue--text' : item.testing}},[_vm._v(_vm._s(item.description.title))]),(item.testing)?[_c('br'),_c('small',{staticClass:"text-decoration-underline font-weight-bold deep-orange--text text-uppercase font-italic"},[_vm._v(_vm._s(_vm.$t('commons.only_for_beta_tester')))])]:_vm._e()]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseDate(item.start))+" ")]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseDate(item.end))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.isLoading)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editMission(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(!_vm.isLoading)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.cloneMission(item.id)}}},[_vm._v(" mdi-content-copy ")]):_vm._e()]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [((item.active))?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-radioactive")]):_vm._e(),(!(item.active))?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-radioactive-off")]):_vm._e()]}}])}),_c('v-divider'),_c('div',{staticClass:"text-center py-3"},[_c('v-pagination',{attrs:{"length":_vm.pagination.total,"total-visible":5,"disabled":_vm.isLoading},on:{"input":function($event){return _vm.getMissions()}},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}})],1),_c('json-debug',{attrs:{"objs":[{missions: _vm.missions}]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-sheet class="elevation-1 pa-5 mission-content"
           id="webview-content"
  >
    <form-content-title :title="$t('missions.types.section_title', {mission: 'Webview'})"/>
    <v-container>
      <v-row>

        <v-col cols="6">
          <v-text-field
              v-model="content.title"
              :label="$t('missions.types.webview.title')"
              :rules="requiredRules"
              outlined
              dense
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              v-model="content.link"
              :label="$t('missions.types.webview.link')"
              :rules="requiredRules"
              outlined
              dense
          ></v-text-field>

        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-textarea
              v-model="content.text"
              :label="$t('missions.types.webview.text')"
              :rules="requiredRules"
              rows="3"
              outlined
              dense
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
              v-model="content.type"
              :label="$t('missions.types.webview.type')"
              :rules="requiredRules"
              outlined
              dense
          ></v-text-field>
        </v-col>
        <v-col cols="8">
          <v-textarea
              v-model="content.json_content"
              :label="$t('missions.types.webview.json_content')"
              :rules="requiredRules"
              outlined
              dense
              rows="5"
          ></v-textarea>

        </v-col>
      </v-row>

    </v-container>
  </v-sheet>
</template>

<script>

import clonedeep from "lodash.clonedeep";
import {validationRules} from "@/mixins/validationRules";
import FormContentTitle from "@/components/commons/FormContentTitle";

export default {
  name: "Webview",

  mixins: [validationRules],

  components: {FormContentTitle},

  props: {
    id: Number,
    form: Object
  },

  data() {
    return {
      defaultContent: {
        title: '',
        text: '',
        link: '',
        type: '',
        json_content: ''
      },
      content: {}
    }
  },

  mounted() {
    if (!this.id) {
      this.content = clonedeep(this.defaultContent)
    } else {
      this.content = this.$store.getters['mission/getMissionContent']
      // fix per import dump incompleti
      if (!this.content) this.content = clonedeep(this.defaultContent)
    }
    this.form.resetValidation()
  },

  watch: {
    "content": {
      handler: function () {
        this.$emit('update:content', this.content)
      },
      deep: true
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <div>
    <v-btn
        color="primary"
        small
        class="mb-2"
        @click="openProductBrowser"
    > {{ $t('products.fields.products.manage') }}
    </v-btn>

    <v-dialog
        v-model="dialog"
        max-width="80%"
        persistent
        overlay-opacity="0.8"
        :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-row>
        <v-col cols="12">
          <v-card>

            <v-container fluid>

              <v-row>
                <v-col cols="12">
                  <v-row v-if="productsList.length > 0">
                    <v-col
                        v-for="product in productsList"
                        :key="product.id"
                        class="d-flex child-flex"
                        cols="4"
                    >
                      <v-card flat tile class="d-flex">
                        <v-img
                            :ref="'image-' + product.id"
                            min-height="200"
                            :src="(product.image) ? product.image.url : '@/assets/no-image.png'"
                            @error="$refs['image-'+product.id].src='@/assets/no-image.png'"
                            class="bordered-product pointer"
                            min-width="90%"
                            contain
                            @click="setProduct(product)"
                            :class="{'selected': ((selected && selected.map(product => product.id).indexOf(product.id) >= 0))}"
                        >

                            <v-row align="end" class="py-0 lightbox white--text pa-2 fill-height">
                              <v-col class="opacity py-0">
                                {{ product.title }}<br>
                                SKU: {{ product.sku_code }}
                              </v-col>
                            </v-row>
                          <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                              <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <div class="text-center py-3">
                    <v-pagination
                        v-model="pagination.current"
                        :length="pagination.total"
                        @input="getProducts()"
                        :disabled="isLoading"
                    ></v-pagination>
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col class="text-center">

                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <v-col
                            v-for="p in selected"
                            :key="p.id"
                            class="d-flex child-flex"
                            cols="2"
                        >
                          <v-card flat tile class="d-inline-flex">
                            <v-img
                                :src="(p.image) ? p.image.url : '@/assets/no-image.png'"
                                class="bordered-product"
                                aspect-ratio="1"
                                max-width="200"
                            >

                              <v-tooltip bottom light>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                      fab
                                      x-small
                                      class="float-right mr-1 mt-1"
                                      color="#BCAAA4"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="removeProduct(p.id)"
                                  >
                                    <v-icon>
                                      {{ $t('icons.trash') }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <small>{{ $t('commons.actions.remove') }}</small>
                              </v-tooltip>

                            </v-img>

                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-btn v-if="selected"
                         class="mx-2"
                         @click="confirm"
                         color="success"
                  >
                    Conferma
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row>
                <v-col>
                  <v-btn color="blue darken-1"
                         text
                         class="mx-2"
                         @click="closeProductBrowser">
                    {{ $t('commons.actions.cancel') }}
                  </v-btn>
                </v-col>
              </v-row>

            </v-container>
          </v-card>
        </v-col>
      </v-row>

    </v-dialog>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import clonedeep from 'lodash.clonedeep'

export default {
  name: "ProductBrowser",

  data() {
    return {
      dialog: false,
      selected: [],
    }
  },

  props: {
    products: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      productsList: 'product/getProducts',
      isLoading: 'product/loadingState',
      pagination: 'product/getPagination'
    }),
    localModel: {
      get() {
        return this.products
      },
      set(products) {
        this.$emit('update:products', products)
        this.reset()
        this.closeProductBrowser()
      }
    }
  },

  // mounted() {
  //   this.selected = clonedeep(this.localModel)
  // },

  methods: {
    openProductBrowser() {
      this.dialog = true
    },

    closeProductBrowser() {
      this.dialog = false
      this.reset()
    },

    getProducts() {
      this.$store.dispatch('product/fetchProducts')
    },

    isSelected(id) {
      return this.selected.map(product => product.id).indexOf(id) > 0
    },

    setProduct(product) {
      console.log('product ', product.id)
      if (this.selected.find(p => p.id === product.id)) {
        this.removeProduct(product.id)
      } else {
        this.selected.push(product)
      }
    },

    removeProduct(id) {
      let removeIndex = this.selected.map(product => product.id).indexOf(id)
      ~removeIndex && this.selected.splice(removeIndex, 1)
    },

    confirm() {
      this.localModel = this.selected
    },

    reset() {
      this.selected = ''
    }
  },

  watch: {
    dialog(visible) {
      if (visible) {
        this.selected = clonedeep(this.localModel)
      }
    }
  },
}
</script>

<style scoped>
.left-border {
  border-left: 1px solid #ddd;
}

.pointer {
  cursor: pointer;
}

div.bordered-product {
  border: 1px solid #ddd;
}

div.selected {
  border: 2px solid red !important;
}

.opacity {
  background-color: #dddddd;
  opacity: .6;
  color: #000000;
  font-weight: bold;
  font-size: 75%;
}
</style>
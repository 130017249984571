<template>
    <v-sheet class="elevation-3 pa-5">

        <mission-form></mission-form>
        <missions-list></missions-list>

    </v-sheet>

</template>

<script>
    import List from "@/components/missions/List";
    import Form from "@/components/missions/Form";

    export default {

        name: "List",

        data () {
            return {
                search: '',
                openFilters: false,
            }
        },

        components: {
          'missions-list': List,
          'mission-form': Form
        }
    }
</script>

<style lang="scss" scoped>
</style>

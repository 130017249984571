<template>
  <v-sheet class="elevation-1 pa-5 mission-content"
           id="webview-content"
  >
    <form-content-title :title="$t('missions.types.section_title', {mission: 'Share'})"/>
    <v-container>
      <v-row>

        <v-col cols="7">
          <v-textarea
              v-model="content.content"
              :label="$t('missions.types.share.description')"
              :rules="requiredRules"
              outlined
              dense
          ></v-textarea>
        </v-col>

        <v-col cols="5">
          <v-text-field
              v-model="content.url"
              :label="$t('missions.types.share.url')"
              :rules="requiredRules"
              outlined
              dense
          ></v-text-field>
        </v-col>
      </v-row>

    </v-container>
  </v-sheet>
</template>

<script>

import clonedeep from "lodash.clonedeep";
import {validationRules} from "@/mixins/validationRules";
import FormContentTitle from "@/components/commons/FormContentTitle";

export default {
  name: "Share",

  mixins: [validationRules],

  props: {
    id: Number,
    form: Object
  },

  components: {FormContentTitle},

  data() {
    return {
      defaultContent: {
        description: '',
        url: ''
      },
      content: {}
    }
  },

  mounted() {
    if (!this.id) {
      this.content = clonedeep(this.defaultContent)
    } else {
      this.content = this.$store.getters['mission/getMissionContent']
      // fix per import dump incompleti
      if (!this.content) this.content = clonedeep(this.defaultContent)
    }
    this.form.resetValidation()
  },

  watch: {
    "content": {
      handler: function () {
        this.$emit('update:content', this.content)
      },
      deep: true
    }
  },
}
</script>

<style scoped>

</style>
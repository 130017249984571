<template>
  <v-sheet class="elevation-1 pa-5 mission-content"
           id="product-content"
  >
    <form-content-title :title="$t('missions.types.section_title', {mission: 'Quiz'})"/>

    <v-container>

      <v-row>
        <v-col cols="8">
          <v-text-field
              v-model="content.description"
              :label="$t('missions.types.quiz.description')"
              :rules="requiredRules"
              outlined
              dense
          ></v-text-field>

          <v-text-field
              v-model="content.text_action"
              :label="$t('missions.types.quiz.text_action')"
              :rules="requiredRules"
              outlined
              dense
          ></v-text-field>

          <questions v-if="content.questions" :questions.sync="content.questions"></questions>

        </v-col>

        <v-col cols="4"
               class="text-center"
        >
          <image-upload id="mission_quiz_background"
                        :image.sync="content.background"
                        :required-rules="requiredRules"
                        :required="true"
                        btn-label="background"
          ></image-upload>

        </v-col>
      </v-row>

    </v-container>
  </v-sheet>
</template>

<script>

import clonedeep from 'lodash.clonedeep'
import {validationRules} from "@/mixins/validationRules";
import ImageUpload from "@/components/form/ImageUpload";
import Questions from "@/components/form/Questions";
import FormContentTitle from "@/components/commons/FormContentTitle";

export default {
  name: "Quiz",

  mixins: [validationRules],

  components: {ImageUpload, Questions, FormContentTitle},

  props: {
    id: Number,
    form: Object
  },

  data() {
    return {
      content: {},
      defaultContent: {
        description: '',
        text_action: '',
        questions: []
      },
    }
  },

  mounted() {
    if (!this.id) {
      this.content = clonedeep(this.defaultContent)
    } else {
      this.content = this.$store.getters['mission/getMissionContent']
      // fix per import dump incompleti
      if (!this.content) this.content = clonedeep(this.defaultContent)
    }
    this.form.resetValidation()
  },

  watch: {
    "content": {
      handler: function () {
        this.$emit('update:content', this.content)
      },
      deep: true
    }
  },

}
</script>

<style scoped>

</style>
<template>
  <div>

    <v-divider class="my-3"></v-divider>

    <commons-filter :loading="isLoading"
                    :freeText.sync="filters.name"
                    :country.sync="filters.country"
                    :appVersion.sync="filters.min_app_version"
                    :testing.sync="filters.testing"
                    :free-search-filter="true"
                    :country-filter="true"
                    :app-version-filter="true"
                    :testing-filter="true"
                    @change="applyFilter"
    />

    <v-data-table
        :loading="isLoading"
        :loading-text="$t('commons.loading')"
        :headers="headers"
        :items="missions"
        :items-per-page="pagination.per_page"
        :hide-default-footer="true"
        class="elevation-0 clickable"
        disable-pagination
    >

      <template v-slot:item.description.title="{ item }">
        <b :class="{'blue--text' : item.testing}">{{ item.description.title }}</b>
        <template v-if="item.testing">
          <br><small class="text-decoration-underline font-weight-bold deep-orange--text text-uppercase font-italic">{{ $t('commons.only_for_beta_tester') }}</small>
        </template>
      </template>

      <template v-slot:item.start="{ item }">
          {{ $parseDate(item.start) }}
      </template>

      <template v-slot:item.end="{ item }">
          {{ $parseDate(item.end) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
            v-if="!isLoading"
            small
            class="mr-2"
            @click="editMission(item)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
            v-if="!isLoading"
            small
            class="mr-2"
            @click="cloneMission(item.id)"
        >
          mdi-content-copy
        </v-icon>

      </template>

      <template v-slot:item.active="{ item }">
        <v-icon color="success" v-if="(item.active)">mdi-radioactive</v-icon>
        <v-icon color="error" v-if="!(item.active)">mdi-radioactive-off</v-icon>
      </template>


    </v-data-table>

    <v-divider></v-divider>

    <div class="text-center py-3">

      <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          :total-visible="5"
          @input="getMissions()"
          :disabled="isLoading"
      ></v-pagination>
    </div>

    <json-debug :objs="[{missions: missions}]"></json-debug>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {EventBus} from "@/event-bus"
import CountryFilter from "@/components/filters/CountryFilter";
import CommonsFilter from "@/components/filters/CommonsFilter";

export default {
  name: "List",

  data() {
    return {
      missionFilter: '',
      filterMenu: false,
      filters: {},
      cloning: false,
      headers: [
        {text: '#', value: 'id', sortable: false, width: '5%'},
        {text: this.$t('missions.fields.description.title'), value: 'description.title', sortable: false},
        {text: this.$t('missions.fields.slug'), value: 'slug', sortable: false},
        {text: this.$t('missions.fields.active.label'), value: 'active', sortable: false, align: "center"},
        {text: this.$t('coupons.fields.min_app_version'), value: 'min_app_version', sortable: false, width: '10%', align: "center"},
        {text: this.$t('missions.fields.country'), value: 'country', sortable: false, align: 'center', width: '10%'},
        {text: this.$t('missions.fields.type'), value: 'type', sortable: false, width: '10%'},
        {text: this.$t('missions.fields.start'), value: 'start', sortable: false, width: '15%', align: 'center'},
        {text: this.$t('missions.fields.end'), value: 'end', sortable: false, width: '15%', align: 'center'},
        {text: '', value: 'actions', sortable: false, width: '8%', align: 'center'},
      ]
    }
  },

  components: {
    CommonsFilter
  },

  mounted() {
    this.reset()
    this.getMissions()
  },

  methods: {
    reset() {
      this.$store.dispatch('mission/reset', this.filters)
    },

    getMissions() {
      this.$store.dispatch('mission/fetchMissions')
    },

    details(v) {
      this.$router.push({
        name: 'mission-show',
        params: {
          id: v.id,
          name: v.name
        }
      })
    },

    editMission (mission) {
      console.log('edit mission ' , mission)
      this.isEditing = true
      EventBus.$emit('edit-mission', mission)
      this.dialog = true
    },

    async cloneMission (mission) {
      console.log('clone mission ' , mission)
      await this.$confirm(this.$t('missions.replicate.confirm')).then(res => {
        console.log('res ' , res)

        this.$apiClient.get('missions/' + mission + '/clone')
            .then(response => {
              this.isEditing = true
              EventBus.$emit('edit-mission', response.data)
              this.dialog = true
            })

      })
    },

    missionDetails(mission) {
      this.$router.push({
        name: 'mission-details',
        params: {
          id: mission.id,
          type: mission.type
        }
      })

    },

    applyFilter() {
      this.$store.dispatch('mission/setFilters', this.filters)
      this.getMissions()
    },

    removeMissionFilter(event) {
      delete this.filters.exact['id']
      this.missionFilter = ''
      this.getMissions()
      event.stopPropagation()
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'mission/fetchingState',
      missions: 'mission/getMissions',
      pagination: 'mission/getPagination'
    }),

    loading: {
      get() {
        return this.isLoading
      },
      set(v) {
        this.$store.dispatch('tenant/setLoadingStatus', v)
      }
    },
  }
}
</script>

<style scoped>

</style>

<template>
  <v-tooltip bottom light>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          fab
          x-small
          :class="classes"
          :color="color"
          v-bind="attrs"
          v-on="on"
          @click="$emit('click')"
          class="elevation-1"
      >
        <v-icon>
          {{ $t('icons.trash') }}
        </v-icon>
      </v-btn>
    </template>
    <small>{{ $t('commons.actions.remove') }}</small>
  </v-tooltip>

</template>

<script>
export default {
  name: "RemoveButton",

  props: {
    color: {
      type: String,
      default: '#BCAAA4'
    },
    classes: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
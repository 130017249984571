<template>

  <div id="test-result-content">
    <template v-for="(question, index) in localModel">

      <v-card :key="index"
              elevation="3"
              class="mb-5"
      >
        <v-card-title>
          <h4 class="flex-fill border-bottom pb-2" :key="'t_' + index">
            {{ $t('missions.types.survey-test.profile') }} {{ index + 1 }}

            <remove-button @click="remove(index)"
                           classes="float-right mr-1 mt-1"
            ></remove-button>

          </h4>
        </v-card-title>

        <v-card-text>
          <v-row :key="index" class="border-bottom mb-5" :id="'question_' + index">
            <v-col cols="8">
              <v-text-field
                  v-model="localModel[index].combination"
                  :label="$t('missions.types.survey-test.combination')"
                  :rules="requiredRules"
                  outlined
                  dense
              ></v-text-field>

              <v-text-field
                  v-model="localModel[index].title"
                  :label="$t('missions.types.survey-test.title')"
                  outlined
                  dense
              ></v-text-field>

              <v-text-field
                  v-model="localModel[index].subtitle"
                  :label="$t('missions.types.survey-test.subtitle')"
                  outlined
                  dense
              ></v-text-field>

              <v-textarea
                  v-model="localModel[index].text"
                  :label="$t('missions.types.survey-test.text')"
                  outlined
                  dense
                  rows="3"
              ></v-textarea>
            </v-col>

            <v-col cols="4"
                   class="text-center"
            >
              <image-upload id="mission_survey_test_background"
                            :image.sync="localModel[index].image"
                            :required-rules="requiredRules"
                            :required="true"
                            btn-label="image"
              ></image-upload>

            </v-col>

          </v-row>
        </v-card-text>
      </v-card>
    </template>

    <v-divider></v-divider>

    <v-row>
      <v-col>
        <v-btn
            @click="add"
            small
            color="primary"
        >
          <v-icon>mdi-plus-circle-outline</v-icon>
          {{ $t('missions.types.survey-test.add') }}
        </v-btn>
      </v-col>
    </v-row>

  </div>

</template>

<script>

import FormContentTitle from "@/components/commons/FormContentTitle";
import clonedeep from "lodash.clonedeep";
import {validationRules} from "@/mixins/validationRules";
import RemoveButton from "@/components/commons/RemoveButton";
import ImageUpload from "@/components/form/ImageUpload";

export default {
  name: "SurveyTestResult",

  mixins: [validationRules],

  components: {ImageUpload, FormContentTitle, RemoveButton},

  data() {
    return {
      newContent: {},
      defaultContent: {
        combination: '',
        title: '',
        subtitle: '',
        text: '',
        image: {
          id: ''
        }
      }
    }
  },

  props: {
    testResults: {
      type: Array,
      required: true
    }
  },

  computed: {
    localModel: {
      get() {
        return this.testResults
      },
      set(content) {
        this.$emit('update:testResults', content)
      }
    }
  },

  methods: {
    add(e) {
      e.preventDefault()
      let newTestResult = clonedeep(this.defaultContent)
      this.localModel.push(newTestResult)
      this.$nextTick(function () {
        var elmnt = document.getElementById('test-result-content');
        elmnt.scrollIntoView(false);
      })
    },

    remove(index) {
      this.localModel.splice(index, 1)
    },
  },
}
</script>

<style scoped>

</style>
<template>
  <v-sheet class="elevation-1 pa-5 mission-content"
           id="product-content"
  >
    <form-content-title :title="$t('missions.types.section_title', {mission: 'Qrcode'})"/>
    <v-container>

        <v-row>

          <v-col cols="6">
            <v-textarea
                v-model="content.check_info"
                :label="$t('missions.types.qrcode.check_info')"
                :rules="requiredRules"
                outlined
                dense
            ></v-textarea>
            <v-switch
                class="mt-1"
                v-model="content.check"
                :label="$t('missions.types.qrcode.check')"
                inset
                color="success"
            ></v-switch>
          </v-col>

          <v-col cols="3">
            <v-select
                v-model="content.type"
                :label="$t('missions.types.qrcode.check_type')"
                :items="checkTypes"
                :rules="requiredRules"
                outlined
                dense
            ></v-select>
          </v-col>

          <v-col cols="3">
            <v-text-field
                v-model="content.url"
                :label="$t('missions.types.qrcode.url')"
                :rules="requiredRules"
                outlined
                dense
            ></v-text-field>
          </v-col>
        </v-row>

    </v-container>
  </v-sheet>
</template>

<script>

import clonedeep from "lodash.clonedeep";
import {validationRules} from "@/mixins/validationRules";
import FormContentTitle from "@/components/commons/FormContentTitle";

export default {
  name: "Qrcode",

  mixins: [validationRules],

  components: {FormContentTitle},

  props: {
    id: Number,
    form: Object
  },

  data() {
    return {
      defaultContent: {
        type: '',
        check_info: '',
        check_type: '',
        url: ''
      },
      content: {},
      checkTypes: [
          'bill',
          'store'
      ]
    }
  },

  mounted() {
    if (!this.id) {
      this.content = clonedeep(this.defaultContent)
    } else {
      this.content = this.$store.getters['mission/getMissionContent']
      // fix per import dump incompleti
      if (!this.content) this.content = clonedeep(this.defaultContent)
    }
    this.form.resetValidation()
  },

  watch: {
    "content": {
      handler: function() {
        this.$emit('update:content', this.content)
      },
      deep: true
    }
  },

}
</script>

<style scoped>

</style>
<template>

  <v-sheet class="elevation-1 pa-5 mission-content"
           id="product-content"
  >
    <form-content-title :title="$t('missions.types.section_title', {mission: 'Survey'})"/>

    <v-container>

      <v-row>
        <v-col id="survey-content">

          <v-row>
            <v-col cols="8">
              <v-text-field
                  v-model="content.description"
                  :label="$t('missions.types.survey.description')"
                  :rules="requiredRules"
                  outlined
                  dense
              ></v-text-field>

              <questions v-if="content.questions" :questions.sync="content.questions"></questions>

            </v-col>

            <v-col cols="4"
                   class="text-center"
            >
              <image-upload id="mission_quiz_background"
                            :image.sync="content.background"
                            :required-rules="requiredRules"
                            :required="true"
                            btn-label="background"
              ></image-upload>

            </v-col>

          </v-row>

          <v-row>
            <v-col>
              <v-switch
                  class="mt-1"
                  v-model="content.is_test"
                  :label="$t('missions.types.survey.is_test.label')"
                  inset
                  color="success"
                  :messages="$t('missions.types.survey.is_test.help_text')"
              >
              </v-switch>
            </v-col>
          </v-row>

          <template v-if="content.is_test">
            <form-content-title :title="$t('missions.types.test_result')"/>
            <survey-test-result v-if="content.is_test" :testResults.sync="content.testResults"></survey-test-result>
          </template>

        </v-col>
      </v-row>
    </v-container>
  </v-sheet>

</template>

<script>

import clonedeep from 'lodash.clonedeep'
import {validationRules} from "@/mixins/validationRules";
import ImageUpload from "@/components/form/ImageUpload";
import Questions from "@/components/form/Questions";
import FormContentTitle from "@/components/commons/FormContentTitle";
import SurveyTestResult from "@/components/missions/_partials/SurveyTestResult";

export default {
  name: "Survey",

  components: {ImageUpload, Questions, SurveyTestResult, FormContentTitle},

  mixins: [validationRules],

  props: {
    id: Number,
    form: Object
  },

  data() {
    return {
      content: {},
      defaultContent: {
        description: '',
        is_test: false,
        background: {},
        questions: [],
        testResults: []
      }
    }
  },

  mounted() {
    if (!this.id) {
      this.content = clonedeep(this.defaultContent)
    } else {
      this.content = this.$store.getters['mission/getMissionContent']
      // fix per import dump incompleti
      if (!this.content) this.content = clonedeep(this.defaultContent)
    }
    this.form.resetValidation()
  },

  watch: {
    "content": {
      handler: function () {
        this.$emit('update:content', this.content)
      },
      deep: true
    }
  },
}
</script>

<style scoped>

</style>
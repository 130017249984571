<template>

  <v-select
      v-model="localModel"
      :label="$t('missions.fields.type')"
      :items="types"
      item-text="name"
      item-value="name"
      :disabled="disabled"
      :rules="requiredRules"
      :required="required"
      outlined
      dense
      @change="$emit('change')"
  ></v-select>

</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: "MissionTypes",

    props: {
      type: String,
      disabled: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      },
      requiredRules: Array
    },

    computed: {
      ...mapGetters({
        types: 'mission/getTypes'
      }),

      localModel: {
        get() {
          return this.type
        },
        set(type) {
          this.$emit('update:type', type)
        }
      }
    },

    mounted() {
      if (this.types.length === 0) {
        this.$store.dispatch('mission/setTypes')
      }
    },


  }
</script>

<style scoped>

</style>
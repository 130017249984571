<template>
  <div id="quiz-content">
    <template v-for="(question, index) in localModel">

      <v-card :key="index"
              elevation="3"
              class="mb-5"
      >
        <v-card-title>
          <h4 class="flex-fill border-bottom pb-2" :key="'t_' + index">
            {{ $t('missions.types.quiz.question') }} {{ index + 1 }}

            <remove-button @click="removeQuestion(index)"
                           classes="float-right mr-1 mt-1"
            ></remove-button>

          </h4>
        </v-card-title>

        <v-card-text>
          <v-row :key="index" class="border-bottom mb-5" :id="'question_' + index">
            <v-col cols="6">
              <v-text-field
                  v-model="localModel[index].question"
                  :label="$t('missions.types.quiz.question')"
                  :rules="requiredRules"
                  outlined
                  dense
              ></v-text-field>

              <v-text-field
                  v-model="localModel[index].description_title"
                  :label="$t('missions.types.quiz.description_title')"
                  outlined
                  dense
              ></v-text-field>

              <v-textarea
                  v-model="localModel[index].description_text"
                  :label="$t('missions.types.quiz.description_text')"
                  outlined
                  dense
                  rows="3"
              ></v-textarea>

              <v-text-field
                  v-model="localModel[index].right_alert"
                  :label="$t('missions.types.quiz.right_alert')"
                  outlined
                  dense
              ></v-text-field>

              <v-text-field
                  v-model="localModel[index].wrong_alert"
                  :label="$t('missions.types.quiz.wrong_alert')"
                  outlined
                  dense
              ></v-text-field>

              <v-text-field
                  v-model="localModel[index].position"
                  :label="$t('missions.types.quiz.position')"
                  outlined
                  dense
              ></v-text-field>
            </v-col>

            <v-col cols="6" class="border-left">

              <template v-for="(answer, a_index) in localModel[index].answers">
                <v-row :key="a_index">
                  <v-col cols="6" class="py-0">
                    <v-text-field
                        v-model="localModel[index].answers[a_index].text"
                        :label="$t('missions.types.quiz.answer') + ' ' + (a_index+1)"
                        :rules="requiredRules"
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-text-field
                        v-model="localModel[index].answers[a_index].value"
                        :label="$t('missions.types.quiz.point')"
                        :rules="requiredRules"
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <remove-button color="transparent"
                                   classes="float-right mt-1"
                                   @click="removeAnswer(index, a_index)"
                    ></remove-button>
                  </v-col>
                </v-row>
              </template>
              <v-btn color="primary" x-small @click="addAnswer($event, index)">{{ $t('missions.types.quiz.add_answer') }}</v-btn>
            </v-col>
          </v-row>

          <v-row v-if="type === 'flavor'">
            <v-col cols="6">
                        <image-upload id="flavor_image_1"
                                      :image.sync="localModel[index].image_1"
                                      :required-rules="requiredRules"
                                      :required="true"
                                      btn-label="image"
                        ></image-upload>
            </v-col>
            <v-col cols="6">
                        <image-upload id="flavor_image_2"
                                      :image.sync="localModel[index].image_2"
                                      :required-rules="requiredRules"
                                      :required="true"
                                      btn-label="image"
                        ></image-upload>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>

    <v-divider></v-divider>

    <v-row>
      <v-col>
        <v-btn
            v-if="!onlyOneQuestion"
            @click="addQuestion"
            small
            color="primary"
        >
          <v-icon>mdi-plus-circle-outline</v-icon>
          {{ $t('missions.types.quiz.add_question') }}
        </v-btn>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import clonedeep from "lodash.clonedeep";
import {validationRules} from "@/mixins/validationRules";
import RemoveButton from "@/components/commons/RemoveButton";
import ImageUpload from "@/components/form/ImageUpload";

export default {
  name: "Questions",

  data() {
    return {
      dialog: false,
      newQuestions: [],
      defaultQuestion: {
        question: '',
        description_title: '',
        description_text: '',
        pre_title: '',
        type: 'quiz',
        position: 1,
        right_alert: '',
        wrong_alert: '',
        background: {
          id: ''
        },
        answers: [
          {
            text: '',
            value: 1
          },
          {
            text: '',
            value: 0
          }
        ],
        image_1: {
          id: ''
        },
        image_2: {
          id: ''
        },
      },
      defaultAnswer: {
        text: '',
        value: 0
      },
    }
  },

  components: {RemoveButton, ImageUpload},

  mixins: [validationRules],

  props: {
    questions: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      default: 'generic'
    },
  },

  computed: {
    localModel: {
      get() {
        return this.questions
      },
      set(questions) {
        this.$emit('update:questions', questions)
      }
    },
    onlyOneQuestion() {
      return (this.type === 'flavor' && this.questions.length === 1)
    }
  },

  methods: {
    addQuestion(e) {
      e.preventDefault()
      let newQuestion = clonedeep(this.defaultQuestion)
      this.localModel.push(newQuestion)
      this.$nextTick(function () {
        var elmnt = document.getElementById('quiz-content');
        elmnt.scrollIntoView(false);
      })
    },

    addAnswer(e, index) {
      e.preventDefault()
      let answer = clonedeep(this.defaultAnswer)

      this.localModel[index].answers.push(answer);
    },

    removeQuestion(index) {
      this.localModel.splice(index, 1)
    },

    removeAnswer(q_index, a_index) {
      this.localModel.[q_index].answers.splice(a_index, 1)
    }
  },

  // watch: {
  //   "localModel": {
  //     handler: function () {
  //       this.$emit('update:questions', this.localModel)
  //     },
  //     deep: true
  //   }
  // },
}
</script>

<style scoped>

</style>
<template>

  <v-sheet class="elevation-1 pa-5 mission-content"
           id="flavor-content"
  >
    <form-content-title :title="$t('missions.types.section_title', {mission: 'Flavor'})"/>

    <v-container>

      <v-row>
        <v-col cols="8">
          <v-row>
            <v-col cols="6">
              <v-text-field
                  v-model="content.description"
                  :label="$t('missions.flavors.description')"
                  :rules="requiredRules"
                  outlined
                  dense
              ></v-text-field>
            </v-col>
          </v-row>

          <questions v-if="content.questions"
                     :questions.sync="content.questions"
                     type="flavor"
          ></questions>

        </v-col>

        <v-col
            cols="4"
            class="text-center"
        >
<!--          <image-upload id="mission_backgroun"-->
<!--                        :image.sync="content.background"-->
<!--                        :required-rules="requiredRules"-->
<!--                        :required="true"-->
<!--                        btn-label="background"-->
<!--          ></image-upload>-->
        </v-col>

      </v-row>

    </v-container>
  </v-sheet>

</template>

<script>

import clonedeep from "lodash.clonedeep";
import ImageUpload from "@/components/form/ImageUpload";
import {validationRules} from "@/mixins/validationRules";
import Questions from "@/components/form/Questions";
import FormContentTitle from "@/components/commons/FormContentTitle";

export default {
  name: "Flavor",

  components: {ImageUpload, Questions, FormContentTitle},

  mixins: [validationRules],

  props: {
    id: Number,
    form: Object
  },

  data() {
    return {
      defaultContent: {
        title: '',
        description: '',
        background: {
          id: ''
        },
        questions: []
      },
      content: {},
    }
  },

  mounted() {
    if (!this.id) {
      this.content = clonedeep(this.defaultContent)
    } else {
      this.content = this.$store.getters['mission/getMissionContent']
      // fix per import dump incompleti
          if (!this.content) this.content = clonedeep(this.defaultContent)
    }
    this.form.resetValidation()
  },

  methods: {
    removeFlavor(id) {
      let removeIndex = this.content.flavors.map(flavor => flavor.id).indexOf(id)
      ~removeIndex && this.content.flavors.splice(removeIndex, 1)
    },
  },

  watch: {
    "content": {
      handler: function () {
        this.$emit('update:content', this.content)
      },
      deep: true
    }
  },
}
</script>

<style scoped>

</style>